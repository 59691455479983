import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import to from 'await-to-js'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { DataTable, DataTableProvider } from 'components/DataTable'
import dialogConfirm from 'components/dialogConfirm'
import { DateCell } from 'components/Tables/CommonTableCells/DateCell'
import { ContactTag, Tag } from 'models/tags'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { z } from 'zod'
import { ColorPicker } from '../../../components/ColorPicker'
import { dataTableSortingStoreRepo } from '../../../components/DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../../components/DataTable/UI'
import FbButton from '../../../components/FbUI/FbButton'
import { TaglistCell } from '../../../components/FbUI/StagePill'
import { TextInput } from '../../../components/FormUtils/TextInput'
import { Modal } from '../../../components/UI/Modal/Modal'
import { TAG_COLORS } from '../../../utils/constants'
import { handleFormError } from '../../../utils/handleFormError'
import { usePaginationURLParams } from '../../../utils/usePaginationURLParams'
import * as S from './styles'

const defaultValuesTag = {
  tag: '',
  description: '',
  color: { background: TAG_COLORS[0].background, text: TAG_COLORS[0].text },
}

const formSchema = z.object({
  tag: z
    .string()
    .min(1, 'Tag name is required')
    .max(50, 'Tag name must be 50 characters or less'),
  description: z.string(),
  color: z.object({
    background: z.string(),
    text: z.string(),
  }),
})

type FormSchema = z.infer<typeof formSchema>

const PAGE_SIZE = 25

const sortableFields = ['tag', 'count', 'modified', 'created']

export function TagTableContent({
  tagParent = 'products',
  tableKey,
}: {
  tagParent: 'products' | 'contacts'
  tableKey: string
}) {
  const api = apiService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedTagID, setSelectedTagID] = useState<number>()
  const { sorting } = dataTableSortingStoreRepo.getStore(tableKey)()

  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    reset(defaultValuesTag)
    setSelectedTagID(undefined)
  }

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    `${tagParent}-tag-page`
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: `tag-table-${tagParent}`,
    filterParams: sortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      const apiAction =
        tagParent === 'contacts'
          ? api.getContactTagsList
          : api.getProductTagsList

      const [err, data] = await to(
        apiAction(
          {
            ...sortParams,
            limit: PAGE_SIZE,
            page: pagination.pageIndex + 1,
          },
          signal
        )
      )

      if (err) {
        throw err
      }

      return data
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<FormSchema>({
    defaultValues: defaultValuesTag,
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const onSubmitCreate = handleSubmit(async (values) => {
    const [err] = await to(
      api.postTag(
        {
          tag: values.tag,
          description: values.description,
        },
        tagParent
      )
    )
    if (err) {
      handleFormError(err, setError)
      toast.error('Error creating tag')
      return
    }

    toast.success('Tag created')
    await refetch()
    hideModal()
  })

  const onSubmitUpdate = handleSubmit(async (values) => {
    if (!selectedTagID) return

    const [err] = await to(
      api.putTag(
        selectedTagID,
        {
          tag: values.tag,
          description: values.description,
          color: values.color.background,
        },
        tagParent
      )
    )
    if (err) {
      handleFormError(err, setError)
      toast.error('Error updating tag')
      return
    }

    toast.success('Tag updated')
    await refetch()
    hideModal()
  })

  async function handleRowDelete(index: number) {
    const id = data?.results?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this tag?',
        'Delete Tag'
      )
    ) {
      const [err] = await to(api.deleteTag(id, tagParent))
      if (err) {
        handleFormError(err, setError)
        toast.error('Error deleting tag')
        return
      }
      toast.success('Tag deleted')
      await refetch()
    }
  }

  function handleRowEdit(index: number) {
    setShowModal(true)
    const tag = data?.results?.[index]
    if (tag) {
      setSelectedTagID(tag.id)
      reset({
        tag: tag.tag,
        description: tag.description,
        color: {
          background: tag?.color || TAG_COLORS[0].background,
          text: tag?.textColor || TAG_COLORS[0].text,
        },
      })
    }
  }

  const columnHelper = createColumnHelper<Tag | ContactTag>()

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <S.SettingsTableHeader>
            <S.TableDescriptionText>
              Create and manage your{' '}
              {tagParent === 'contacts' ? 'contact labels' : 'tags'}
            </S.TableDescriptionText>
            <FbButton onClick={() => openModal()}>
              {tagParent === 'contacts' ? 'Create Contact Label' : 'Create Tag'}
            </FbButton>
          </S.SettingsTableHeader>
        }
        tableKey={tableKey}
        data={data?.results ?? []}
        columns={[
          columnHelper.accessor('tag', {
            header: 'Name',
            size: 200,
            cell: (info) => <TaglistCell taglist={[info.row.original]} />,
          }),
          columnHelper.accessor('description', {
            header: 'Description',
            size: 150,
          }),
          tagParent === 'contacts'
            ? columnHelper.accessor('contact_count', {
                id: 'count',
                header: 'Unique Contacts',
                size: 75,
              })
            : columnHelper.accessor('opp_count', {
                id: 'count',
                header: 'Unique Companies',
                size: 75,
              }),
          columnHelper.accessor('modified', {
            header: 'Modified',
            size: 100,
            cell: DateCell,
          }),
          columnHelper.accessor('created', {
            header: 'Created',
            size: 100,
            cell: DateCell,
          }),
          columnHelper.display({
            id: 'edit',
            header: 'Actions',
            meta: { rightAlign: true },
            cell: ({ row }) => {
              return (
                <ActionButtons
                  className={'justify-end pr-0'}
                  onDelete={() => handleRowDelete(row.index)}
                  onEdit={() => handleRowEdit(row.index)}
                />
              )
            },
            size: 100,
          }),
        ]}
        sortableFields={sortableFields}
        isPaginationEnabled={true}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        loading={isFetching}
      />
      <Modal
        open={showModal}
        title={
          tagParent === 'contacts'
            ? selectedTagID
              ? 'Edit Contact Label'
              : 'Add Contact Label'
            : 'Tag'
        }
        description={
          tagParent === 'contacts'
            ? 'Create or edit a contact label to better manage your contacts'
            : 'Edit your tag here.'
        }
        onOpenChange={(open) => !open && hideModal()}
        loading={isSubmitting}
        blockAccept={!isValid}
        onAccept={selectedTagID ? onSubmitUpdate : onSubmitCreate}
        acceptButtonText={selectedTagID ? 'Update' : 'Create'}
      >
        <TextInput
          name="tag"
          control={control}
          type="text"
          label={tagParent === 'contacts' ? 'Contact Label Name' : 'Tag Name'}
          placeholder="Tag Stage"
        />
        <TextInput
          name="description"
          control={control}
          type="text"
          label="Description"
          placeholder="Description"
        />
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <ColorPicker
                label={'Color'}
                colors={TAG_COLORS}
                value={field.value}
                onChange={field.onChange}
              />
            )
          }}
          name={'color'}
        />
      </Modal>
    </DataTableContainer>
  )
}

export function TagTable({
  tagParent = 'products',
}: {
  tagParent: 'products' | 'contacts'
}) {
  return (
    <ColumnsStoreProvider tableKey={`tag-table-${tagParent}`}>
      <DataTableProvider tableKey={`tag-table-${tagParent}`}>
        <TagTableContent
          tableKey={`tag-table-${tagParent}`}
          tagParent={tagParent}
        />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
