import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'
import { DOORS_COLUMNS } from 'components/DataExpansionComponents/Doors/doorsColumnsInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export default function DoorExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportDoors = async ({
    filter,
    selectedColumns,
    selectedProductId,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    selectedProductId?: number
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.downloadDoorsCsvV2(
      {
        ...filter,
        product_id: selectedProductId,
      },
      selectedColumns,
      exportType,
      props.selectedIds,
      props.excludeIds,
      controller?.signal
    )

    downloadFile(
      res,
      `First Bite Export: Doors - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const doorColumnHelper = createExportColumnHelper({
    columns: DOORS_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        doorColumnHelper.retrieveColumn('restaurant_name'),
        doorColumnHelper.retrieveColumn('chain_name'),
        doorColumnHelper.retrieveColumn('chain_count'),
        doorColumnHelper.retrieveColumn('ingredients_matrix'),
        doorColumnHelper.retrieveColumn('menu_size'),
        doorColumnHelper.retrieveColumn('menu_matches'),
        doorColumnHelper.retrieveColumn('menu_url'),
        doorColumnHelper.retrieveColumn('cuisine_50'),
        doorColumnHelper.retrieveColumn('velocity_group'),
        doorColumnHelper.retrieveColumn('expense_category'),
        doorColumnHelper.retrieveColumn('dmanamecbsa'),
        doorColumnHelper.retrieveColumn('full_address'),
        doorColumnHelper.retrieveColumn('city'),
        doorColumnHelper.retrieveColumn('state'),
        doorColumnHelper.retrieveColumn('zip'),
        doorColumnHelper.retrieveColumn('google_place_url'),
        doorColumnHelper.retrieveColumn('phone'),
        doorColumnHelper.retrieveColumn('website'),
        doorColumnHelper.retrieveColumn('domain'),
      ],
    },
    {
      title: 'Reputation Insights',
      elements: [
        doorColumnHelper.retrieveColumn('avg_rating'),
        doorColumnHelper.retrieveColumn('reviews_count'),
        doorColumnHelper.retrieveColumn('instagram_url'),
        doorColumnHelper.retrieveColumn('instagram_followers'),
        doorColumnHelper.retrieveColumn('instagram_following'),
        doorColumnHelper.retrieveColumn('instagram_posts'),
        doorColumnHelper.retrieveColumn('instagram_text_percentile'),
        doorColumnHelper.retrieveColumn('reputation_data'),
        doorColumnHelper.retrieveColumn('michelin_stars'),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: [
        doorColumnHelper.retrieveColumn('total_ltv'),
        doorColumnHelper.retrieveColumn('brand_ltv'),
        doorColumnHelper.retrieveColumn('revenue_ltv'),
        doorColumnHelper.retrieveColumn('one_year_total_value'),
        doorColumnHelper.retrieveColumn('tabo'),
        doorColumnHelper.retrieveColumn('taro'),
        doorColumnHelper.retrieveColumn('pounds_per_year'),
        ...getCommonChainProxyExportColumns(),
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Restaurant Doors Download"
      subTitle="Download up to 10,000 doors at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Doors"
      columns={columns}
      exportAPIAction={exportDoors}
      {...props}
    />
  )
}
