import { FBPage } from 'components/FbUI/Page/FBPage'
import { Tabs } from '../../../components/FbUI/Tabs'
import { DistributorTable } from './DistributorTable'
import { MiscSettingsTable } from './MiscSettingsTable'
import { SalesStageTable } from './SalesStageTable'
import { SavedFiltersTable } from './SavedFiltersTable'
import { TagTable } from './TagTable'
import { UserTable } from './UserTable'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from '../../../components/FbUI/Page/styles'

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 95px)'

export function Settings() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.type ?? 'users'
  const store = useNavbarStore()

  const tabs = [
    { key: 'users', title: 'Users' },
    { key: 'sales-stages', title: 'Sales Stages' },
    { key: 'filters', title: 'Filters' },
    { key: 'distributors', title: 'Distributors' },
    { key: 'product-tags', title: 'Tags' },
    { key: 'contact-tags', title: 'Contact Labels' },
    { key: 'misc', title: 'Misc. Settings' },
  ]

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Settings</FBPageTitle>
        </FBPageTitleRow>
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/settings/${value}/`
            navigate(url)
            store.setLastSettingsVisited(url)
          }}
          tabStyle={{ width: '125px' }}
        />
      </FBPageInnerTabsContainer>

      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'users'}
      >
        <UserTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'sales-stages'}
      >
        <SalesStageTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'filters'}
      >
        <SavedFiltersTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'distributors'}
      >
        <DistributorTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'product-tags'}
      >
        <TagTable tagParent="products" />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'contact-tags'}
      >
        <TagTable tagParent="contacts" />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'misc'}
      >
        <MiscSettingsTable />
      </FBPageTableContainer>
    </FBPage>
  )
}
