import { ColumnDef } from '@tanstack/react-table'
import to from 'await-to-js'
import dialogConfirm from 'components/dialogConfirm'
import ModalForm from '../../../components/FormUtils/ModalForm'
import { Cuisine50Options } from 'models/cuisine_50'
import { useMemo, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import { Product } from '../../../models/product'
import { handleFormError } from '../../../utils/handleFormError'
import { EstimatedSalesPotential } from './EstimatedSalesPotential'
import FbButton from '../../../components/FbUI/FbButton'
import { TextInput } from 'components/FormUtils/TextInput'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { FBPage } from 'components/FbUI/Page/FBPage'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'
import {
  FBPageHeader,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from '../../../components/FbUI/Page/styles'

const productShape = z
  .object({
    name: z.string().min(1, 'Name is required'),
    format: z.string().min(1, 'Format is required'),
    mfg_avg_list_price_per_lb: z
      .number({ invalid_type_error: 'Average revenue per pound is required' })
      .gt(0, 'Average revenue per pound must be greater than 0'),
    avg_serving_size_per_lb: z
      .number({ invalid_type_error: 'Number of servings per lb is required' })
      .gt(0, 'Number of servings per lb must be greater than 0'),
    est_monthly_churn_rate: z
      .number({
        invalid_type_error: 'Estimated monthly churn rate is required',
      })
      .gt(0, 'Estimated monthly churn rate must be greater than 0'),
    price_per_case: z.number().nullable(),
    value_per_consumer_new_trial: z.number().nullable(),
    lbs_per_case: z.number().nullable(),
    current_aided_awareness: z.number().nullable(),
    current_national_trial_perc: z.number().nullable(),
    value_per_consumer_new_informed: z.number().nullable(),
    estimated_sales_potential: z.any(),
  })
  .transform((data) => {
    // Transform null values to 0
    return {
      ...data,
      price_per_case: data.price_per_case ?? 0,
      value_per_consumer_new_trial: data.value_per_consumer_new_trial ?? 0,
      lbs_per_case: data.lbs_per_case ?? 0,
      current_aided_awareness: data.current_aided_awareness ?? 0,
      current_national_trial_perc: data.current_national_trial_perc ?? 0,
      value_per_consumer_new_informed:
        data.value_per_consumer_new_informed ?? 0,
    }
  })

const espValidationSchema = z.object({
  // each key in esp form should be a non-negative number
  ...Object.fromEntries(
    Cuisine50Options.map((cuisine) => [
      cuisine.value,
      z.number().min(0, `Volume estimates cannot be negative.`),
    ])
  ),
})

const defaultValues = {
  name: '',
  format: '',
  price_per_case: 0,
  mfg_avg_list_price_per_lb: 0,
  avg_serving_size_per_lb: 0,
  est_monthly_churn_rate: 0,
  value_per_consumer_new_trial: 0,
  lbs_per_case: 0,
  current_aided_awareness: 0,
  current_national_trial_perc: 0,
  value_per_consumer_new_informed: 0,
  estimated_sales_potential: {},
}

const defaultESPValues: Product['estimated_sales_potential'] = {}

Cuisine50Options.forEach((cuisine) => {
  defaultESPValues[cuisine.value] = 0
})

export function ProductPage() {
  const api = apiService()

  const { data, isLoading, refetch } = api.useProducts()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedProductID, setSelectedProductID] = useState<number>()

  const {
    control: espControl,
    handleSubmit: handleESPSubmit,
    reset: espReset,
    formState: { isValid: isESPValid },
  } = useForm({
    defaultValues: defaultESPValues,
    resolver: zodResolver(espValidationSchema),
    mode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(productShape),
  })

  const onSubmitCreate = handleSubmit(async (values) => {
    let esp = {} as Product['estimated_sales_potential']
    await handleESPSubmit((data) => (esp = data), console.warn)()

    const [err] = await to(
      api.postProduct({
        ...values,
        estimated_sales_potential: esp,
      })
    )
    if (err) handleFormError(err, setError)

    await refetch()
    setShowModal(false)
  })

  const onSubmitUpdate = handleSubmit(async (values) => {
    let esp = {} as Product['estimated_sales_potential']
    await handleESPSubmit((data) => (esp = data), console.warn)()

    if (!selectedProductID) return

    Object.keys(esp).forEach((key) => {
      const curValue = esp[key]
      esp[key] = curValue ? curValue : 0
    })

    const [err] = await to(
      api.putProduct(selectedProductID, {
        ...values,
        estimated_sales_potential: esp,
      })
    )
    if (err) handleFormError(err, setError)

    await refetch()

    setShowModal(false)
  })

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Product Name',
        size: 200,
      },

      {
        accessorKey: 'format',
        header: 'Product Format',
        size: 150,
      },

      {
        id: 'edit',
        header: '',
        cell: ({ row }) => {
          return (
            <ActionButtons
              onDelete={() => handleRowDelete(row.index)}
              onEdit={() => handleRowEdit(row.index)}
            />
          )
        },
        size: 150,
      },
    ],
    [data]
  )

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    reset(defaultValues)
    espReset(defaultESPValues)
    setSelectedProductID(undefined)
  }

  async function handleRowDelete(index: number) {
    const id = data?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this product? This action will also delete any deals associated with the product.',
        'Delete Product'
      )
    ) {
      try {
        await api.deleteProduct(id)
      } catch {
        /* empty */
      }
      await refetch()
    }
  }

  function handleRowEdit(index: number) {
    const selectedESP = data?.find(
      (product) => product.id === data?.[index]?.id
    )?.estimated_sales_potential

    setShowModal(true)
    setSelectedProductID(data?.[index]?.id)
    espReset(selectedESP)
    if (data) reset(data?.[index])
  }

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Products</FBPageTitle>
        </FBPageTitleRow>
        <FbButton onClick={() => openModal()}>Create Product</FbButton>
      </FBPageHeader>

      <FBPageTableContainer
        style={{ height: 'calc(var(--base-page-table-height) - 47px)' }}
      >
        <ClientDataTable
          height={'100%'}
          data={data}
          columns={columns}
          tableFooter={
            <div style={{ marginTop: 24, width: '50%' }}>
              <strong>First Bite LTV Version 1.4</strong>
              <div style={{ marginBottom: 12 }}>
                Current LTV Drivers: Cuisine, review composition, cross-platform
                ratings, menu dynamics, expense quartiles, chain size,
                demographics, population density, repeater rate, awareness
                effect, user inputs (churn, servings/lb, etc)
              </div>
              <i>Allow up to an hour for LTV to calculate when updated.</i>
            </div>
          }
        />
      </FBPageTableContainer>

      <ModalForm
        show={showModal}
        title={'Product'}
        subtitle={'Edit your product here.'}
        onHide={hideModal}
        loading={isSubmitting}
        allowSubmit={isValid && isESPValid}
        handleSubmit={selectedProductID ? onSubmitUpdate : onSubmitCreate}
      >
        <>
          <Row>
            <Col md={12}>
              <TextInput
                control={control}
                name="name"
                type="text"
                label="Name*"
                placeholder="Product Name"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="format"
                control={control}
                type="text"
                label="Format*"
                placeholder="Product Format"
              />
            </Col>
            <Col>
              <TextInput
                name="price_per_case"
                control={control}
                type="number"
                label="Price per Case"
                placeholder="Price per Case"
                prefix="$"
              />
            </Col>
            <Col>
              <TextInput
                name="mfg_avg_list_price_per_lb"
                control={control}
                type="number"
                label="Average revenue per pound*"
                placeholder="Average revenue per pound"
                prefix="$"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="avg_serving_size_per_lb"
                control={control}
                type="number"
                label="Number of servings per lb*"
                placeholder="Number of servings per lb"
              />
            </Col>
            <Col>
              <TextInput
                name="value_per_consumer_new_trial"
                control={control}
                type="number"
                label="Value per consumer of new informed trial"
                placeholder="Value per consumer of new informed trial"
                prefix="$"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="est_monthly_churn_rate"
                control={control}
                type="number"
                label="Estimated monthly churn rate*"
                placeholder="Estimated monthly churn rate"
                suffix="%"
              />
            </Col>
            <Col>
              <TextInput
                name="value_per_consumer_new_informed"
                control={control}
                type="number"
                label="Value per consumer of new informed aware"
                placeholder="Value per consumer of new informed aware"
                prefix="$"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="current_aided_awareness"
                control={control}
                type="number"
                label="Current Aided Awareness"
                placeholder="Current Aided Awareness"
                suffix="%"
              />
            </Col>
            <Col>
              <TextInput
                name="current_national_trial_perc"
                control={control}
                type="number"
                label="Current National Trial %"
                placeholder="Current National Trial %"
                suffix="%"
              />
            </Col>

            <Col>
              <TextInput
                name="lbs_per_case"
                control={control}
                type="number"
                label="Lbs per case"
                placeholder="Lbs per case"
              />
            </Col>
          </Row>
          <EstimatedSalesPotential control={espControl} />
        </>
      </ModalForm>
    </FBPage>
  )
}
