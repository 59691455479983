import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { CHAIN_COLUMNS } from 'components/DataExpansionComponents/Chains/chainColumnsInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { DataTableColumnSort } from 'components/DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from 'components/FbUI/FbLink'
import { OverflownText } from 'components/OverflownText'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { PredictedTrafficBadge } from 'components/PredictedTrafficBadge/PredictedTrafficBadge'
import { Anchor } from 'components/UI/Anchor'
import { Chain } from 'models/chains'
import { ContactCompanyType } from 'models/contact_companies'
import { menu_ingredients } from 'models/menu_ingredients'
import { Preferences } from 'models/preferences'
import { ChainsSummary } from 'models/summaries'
import { useMemo } from 'react'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiFoodMenu } from 'react-icons/bi'
import { featureFlagService } from 'utils/featureFlagService'
import { formatInteger, formatPercentage, formatUsd } from 'utils/formatting'
import { getPathFromUrl } from 'utils/getPathFromUrl'

export function useChainsTableColumns(
  chainsSummary: ChainsSummary | undefined,
  setMenuModal: any,
  sorting: DataTableColumnSort[] | undefined,
  data: any,
  selectedProducts: number[],
  preferences: Preferences | undefined
) {
  const featureFlag = featureFlagService()
  const tooltips: Record<string, any> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<Chain>()
  const chainColumnHelper = createTableColumnHelper({
    columns: CHAIN_COLUMNS,
    tooltips,
  })

  const defaultCell = (info: CellContext<Chain, any>) => {
    const value = info.getValue()
    if (!value) return '-'
    return value
  }

  const columns = useMemo(() => {
    const commonColumns = getCommonContactCompanyColumns(columnHelper, sorting)

    let cols = [
      columnHelper.accessor('chain', {
        ...chainColumnHelper.retrieveColumn('chain', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 240,
        cell: (info) => {
          if (!info.getValue()) return '-'
          return (
            <FbLink
              to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${info.row.original.id}`}
              target="_blank"
            >
              {info.getValue()}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor('count', {
        ...chainColumnHelper.retrieveColumn('count', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 110,
        cell: (info) => {
          return info.getValue ? formatInteger(info.getValue()) : '-'
        },
      }),
      columnHelper.display({
        ...chainColumnHelper.retrieveColumn('menu', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 100,
        cell: (info) => {
          const items = info.row.original.search_items?.map(
            (item) => item.item_id
          )
          const menuLength = info.row.original.menu_length

          if (!menuLength)
            return (
              <div className="ml-1">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Unavailable</Tooltip>}
                >
                  <div className="max-w-max">
                    <BiFoodMenu size={20} className="text-gray-400" />
                  </div>
                </OverlayTrigger>
              </div>
            )

          return (
            <div className="ml-1">
              <BiFoodMenu
                size={20}
                onClick={() =>
                  setMenuModal({
                    chainId: info.row.original.id,
                    chainName: info.row.original.chain,
                    itemsIds: items,
                    menuLength,
                  })
                }
                className="cursor-pointer"
              />
            </div>
          )
        },
      }),
      columnHelper.accessor('menu_length', {
        ...chainColumnHelper.retrieveColumn('menu_length'),
        size: 100,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      }),
      columnHelper.accessor('menu_matches', {
        ...chainColumnHelper.retrieveColumn('menu_matches', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 110,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      }),
      columnHelper.accessor('total_ltv', {
        ...chainColumnHelper.retrieveColumn('total_ltv', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        header: () => {
          return chainsSummary?.avg_total_ltv ? (
            <>
              <div style={{ marginTop: '12px' }}>Lifetime Total Value</div>
              <span>Avg: {formatUsd(chainsSummary?.avg_total_ltv)}</span>
            </>
          ) : (
            <span>Lifetime Total Value</span>
          )
        },
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('brand_ltv', {
        ...chainColumnHelper.retrieveColumn('brand_ltv', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('revenue_ltv', {
        ...chainColumnHelper.retrieveColumn('revenue_ltv', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('one_year_total_value', {
        ...chainColumnHelper.retrieveColumn('one_year_total_value', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('taro', {
        ...chainColumnHelper.retrieveColumn('taro', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 180,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('tabo', {
        ...chainColumnHelper.retrieveColumn('tabo', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('pounds_per_year', {
        ...chainColumnHelper.retrieveColumn('pounds_per_year', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        header: () => {
          return chainsSummary?.avg_pounds_per_year ? (
            <>
              <div style={{ marginTop: '12px' }}>1yr Volume (lbs)</div>
              <span className={'text-xs'}>
                Avg: {formatInteger(chainsSummary?.avg_pounds_per_year)}
              </span>
            </>
          ) : (
            <span>1yr Volume (lbs)</span>
          )
        },
        cell: (info) =>
          info.getValue() ? formatInteger(info.getValue()) : '-',
      }),
      columnHelper.accessor('domain', {
        ...chainColumnHelper.retrieveColumn('domain'),
        size: 200,
        cell: (info) => {
          const val = info.getValue()
          return val ? (
            <Anchor href={'//' + val} target="_blank">
              {val}
            </Anchor>
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('cuisine_50', {
        ...chainColumnHelper.retrieveColumn('cuisine_50'),
        size: 150,
        cell: defaultCell,
      }),
      columnHelper.accessor('velocity_group', {
        ...chainColumnHelper.retrieveColumn('velocity_group'),
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      }),
      columnHelper.accessor('rating', {
        ...chainColumnHelper.retrieveColumn('rating'),
        size: 70,
        cell: (info) => (info.getValue() ? info.getValue().toFixed(2) : '-'),
      }),
      columnHelper.accessor('reviews_count', {
        ...chainColumnHelper.retrieveColumn('reviews_count', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (ctx) => (ctx.getValue() ? formatInteger(ctx.getValue()) : '-'),
      }),
      columnHelper.accessor('expense_category', {
        ...chainColumnHelper.retrieveColumn('expense_category'),
        size: 100,
        cell: defaultCell,
      }),
      columnHelper.accessor('ingredients_matrix', {
        ...chainColumnHelper.retrieveColumn('ingredients_matrix'),
        size: 300,
        cell: (info) => {
          const ingredients = info.row.original.ingredients_matrix
          const display: string[] = []

          if (!ingredients) return null

          const ingredientsValuesWhitelist = menu_ingredients.map(
            (item) => item.value
          )

          Object.entries(ingredients)
            .filter(([key]) => ingredientsValuesWhitelist.includes(key))
            .map(([key, value]) => {
              if (Number(value) > 0) display.push(key)
            })

          return (
            <OverflownText maxLines={2}>{display.join(', ')}</OverflownText>
          )
        },
      }),
      columnHelper.accessor('instagram.url', {
        ...chainColumnHelper.retrieveColumn('instagram_url'),
        cell: (info) => {
          const value = info.row.original.instagram?.url
          if (!value) return '-'

          return (
            <Anchor href={value} target="_blank">
              @{getPathFromUrl(value)}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('instagram.followers', {
        ...chainColumnHelper.retrieveColumn('instagram_followers', {
          meta: {
            rightAlign: true,
          },
        }),
        cell: (info) => {
          const value = info.row.original.instagram?.followers
          if (!value) return '-'
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('instagram.following', {
        ...chainColumnHelper.retrieveColumn('instagram_following', {
          meta: {
            rightAlign: true,
          },
        }),
        cell: (info) => {
          const value = info.row.original.instagram?.following
          if (!value) return '-'
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('instagram.posts', {
        ...chainColumnHelper.retrieveColumn('instagram_posts', {
          meta: {
            rightAlign: true,
          },
        }),
        cell: (info) => {
          const value = info.row.original.instagram?.posts
          if (!value) return '-'
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('instagram.text_percentile', {
        ...chainColumnHelper.retrieveColumn('instagram_text_percentile'),
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      }),
      ...commonColumns,
      columnHelper.accessor('country_summary', {
        ...chainColumnHelper.retrieveColumn('country_summary'),
        size: 300,
        cell: (info) => {
          const countries = info.getValue()
          if (!countries) return '-'

          const countriesSorted = countries?.split(', ').sort()
          const display = countriesSorted?.slice(0, 25).join(', ')
          const totalCountries = countriesSorted?.length
          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalCountries} more)`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      }),
      columnHelper.accessor('state_summary', {
        ...chainColumnHelper.retrieveColumn('state_summary'),
        size: 300,
        cell: (info) =>
          info.getValue() ? (
            <OverflownText maxLines={2}>{info.getValue()}</OverflownText>
          ) : (
            '-'
          ),
      }),
      columnHelper.accessor('city_summary', {
        ...chainColumnHelper.retrieveColumn('city_summary'),
        size: 300,
        cell: (info) => {
          const cities = info.getValue()
          if (!cities) return '-'

          const citiesSorted = cities?.split(', ').sort()
          const display = citiesSorted?.slice(0, 25).join(', ')
          let totalCities: number | string = citiesSorted?.length
          if (totalCities > 1000) {
            totalCities = Math.floor(totalCities / 1000)
            totalCities = totalCities + 'k'
          }
          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalCities} more)`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      }),
      columnHelper.accessor('zip_summary', {
        ...chainColumnHelper.retrieveColumn('zip_summary'),
        size: 300,
        cell: (info) => {
          const zips = info.getValue()
          if (!zips) return '-'

          const zipSorted = zips?.split(', ').sort()
          const display = zipSorted?.slice(0, 25).join(', ')
          let totalZipCodes: number | string = zipSorted?.length
          if (totalZipCodes > 1000) {
            totalZipCodes = Math.floor(totalZipCodes / 1000)
            totalZipCodes = totalZipCodes + 'k'
          }
          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalZipCodes} more)`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      }),
      columnHelper.accessor('median_hhi', {
        ...chainColumnHelper.retrieveColumn('median_hhi'),
        cell: (info) => formatUsd(info.getValue()),
      }),
      columnHelper.accessor('pop_density', {
        ...chainColumnHelper.retrieveColumn('pop_density'),
        cell: (info) => formatInteger(info.getValue() ?? 0) + '/mi²',
      }),
      columnHelper.accessor('hh_gt100k', {
        ...chainColumnHelper.retrieveColumn('hh_gt100k'),
        cell: (info) => {
          const value = info.getValue() * 100
          formatPercentage(value ?? 0) + '%'
        },
      }),
      columnHelper.display({
        ...chainColumnHelper.retrieveColumn('reputation_data'),
        size: 200,
        cell: (info) => {
          const { michelin_ids_count, eater_ids_count, infatuation_ids_count } =
            // eslint-disable-next-line no-unsafe-optional-chaining
            info.row?.original
          const reputationPlatforms = [
            michelin_ids_count && 'Michelin',
            eater_ids_count && 'Eater',
            infatuation_ids_count && 'Infatuation',
          ].filter(Boolean)

          if (reputationPlatforms.length === 0) return '-'
          return (
            <div
              style={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {reputationPlatforms.map((platform, index) => (
                <Badge key={index} className="!bg-green-500">
                  {platform}
                </Badge>
              ))}
            </div>
          )
        },
      }),
      columnHelper.accessor('michelin_stars_count', {
        ...chainColumnHelper.retrieveColumn('michelin_stars_count'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (value === undefined || value === null) return '-'
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { michelin_ids_count } = info.row?.original
          if (!michelin_ids_count) return '-'
          return value
        },
      }),
    ]

    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }

    if (!featureFlag.enableInstagremData) {
      cols = cols.filter((col) => !col.id?.includes('instagram'))
    }

    if (!featureFlag.enableInstagramPercentile) {
      cols = cols.filter((col) => col.id !== 'instagram_text_percentile')
    }

    if (!featureFlag.shouldShowMenu) {
      cols = cols.filter((col) => col.id !== 'menu')
    }

    if (!featureFlag.shouldShowMenuSize) {
      cols = cols.filter((col) => col.id !== 'menu_length')
    }

    if (!featureFlag.shouldShowReputationData) {
      cols = cols.filter(
        (col) =>
          !['reputation_data', 'michelin_stars_count'].includes(col?.id ?? '')
      )
    }

    return cols
  }, [
    data,
    selectedProducts,
    sorting,
    preferences,
    featureFlag.enableInstagremData,
    featureFlag.shouldShowMenu,
    featureFlag.shouldShowMenuSize,
    featureFlag.shouldShowReputationData,
  ])

  return columns
}
