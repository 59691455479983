import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import to from 'await-to-js'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { Editor } from 'components/Editor/Editor'
import { AFbLink } from 'components/FbUI/FbLink/styles'
import NotesModal from 'components/Modals/NotesModal'
import dialogConfirm from 'components/dialogConfirm'
import { JsonIntoUl } from 'components/json-into-ul'
import { INote } from 'models/contact_companies'
import { ContactResponse } from 'models/contacts'
import { useEffect, useMemo, useState } from 'react'
import { BiExpandAlt } from 'react-icons/bi'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import styled from 'styled-components'
import { API_HOST } from 'utils/http-client'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { useAuth } from '../../../context/authentication/useAuth'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { ActionButtons } from '../../Buttons/ActionButtons'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { IColumnVisibility } from '../../DataTable/types'
type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = []

const PAGE_SIZE = 100

interface NotesTableProps {
  setTotalRowsCount?: (count: number) => void
  enablePagination?: boolean
  disableUrlPagination?: boolean
  chainId: number | undefined
  contactCompanyId: number | undefined
  tableKey: string
}

function NotesTableComponent(props: NotesTableProps) {
  const [activeNote, setActiveNote] = useState<{
    note: INote
    isViewing?: boolean
  } | null>(null)
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [pagination, setPagination] = props.disableUrlPagination
    ? useState({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      })
    : usePaginationURLParams(PAGE_SIZE)
  const api = apiService()
  const { user: me } = useAuth()

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...sortParams,
    }),
    [sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      if (!props.chainId && !props.contactCompanyId)
        return {
          results: [],
          count: 0,
        }

      const res = await api.getNotes(
        props.chainId,
        props.contactCompanyId,
        {
          ...filterAndSortParams,
          page: pagination.pageIndex + 1,
        },
        signal
      )

      props.setTotalRowsCount?.(res.count)
      return res
    },
  })
  // END DATA FETCHING

  const columnHelper = createColumnHelper<INote>()

  const columns = [
    columnHelper.accessor('user', {
      header: 'User',
      cell: (info) => {
        const row = info.row.original
        return !row.user
          ? `${me?.company.name} User`
          : row.user?.first_name + ' ' + row.user?.last_name
      },
      size: 40,
    }),
    columnHelper.accessor('content', {
      header: 'Note',
      cell: (info) => {
        const value = info.getValue()
        if (!value) return '--'

        return (
          <JsonIntoUl
            value={value}
            fallback={
              <Editor
                value={value}
                readOnly={true}
                disableToolbar={true}
                noBorder
              />
            }
          />
        )
      },
    }),
    columnHelper.accessor('attachments', {
      header: 'Attachments',
      cell: (info) => {
        const attachments = info.getValue()
        return (
          <div className="flex flex-col">
            {attachments?.map((attachment) => {
              const href = attachment?.file?.startsWith('/')
                ? `${API_HOST}${attachment.file}`
                : attachment.file
              return (
                <AFbLink
                  key={attachment.id}
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment.original_filename}
                </AFbLink>
              )
            })}
          </div>
        )
      },
      size: 100,
    }),
    columnHelper.accessor('created', {
      header: 'Date Added',
      cell: (info) => {
        const date = info.getValue()
        if (!date) return '-'

        return new Date(date).toLocaleDateString()
      },
      size: 40,
    }),
    columnHelper.accessor('modified', {
      header: 'Date Modified',
      cell: (info) => {
        const date = info.getValue()
        if (!date) return '-'

        return new Date(date).toLocaleDateString()
      },
      size: 40,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      size: 20,
      meta: {
        isEditable: false,
        isLastColumn: true,
      },
      cell: (info) => {
        const note = info.row.original
        const noteId = note.id
        return (
          <ActionButtons
            className={'justify-start'}
            onDelete={() => handleNoteDelete(noteId)}
            onEdit={() => setActiveNote({ note })}
            onSee={() => setActiveNote({ note, isViewing: true })}
            seeIcon={BiExpandAlt}
          />
        )
      },
    }),
  ]

  const handleNoteDelete = async (noteId: number) => {
    if (
      await dialogConfirm(
        <>
          Are you sure you want to delete this <b>note</b>? This action cannot
          be undone.
        </>,
        'Delete Note?',
        '',
        { confirmLabel: 'Delete' }
      )
    ) {
      const [err] = await to(api.deleteNote(noteId))
      if (!err) {
        toast.success('Note deleted successfully')
        refetch()
      }
    }
  }

  useEffect(() => {
    const totalCount = data?.count ?? 0
    props.setTotalRowsCount?.(totalCount)
    setTotalRowsInBackend?.(totalCount)
  }, [data?.count])

  return (
    <TableContainer>
      <DataTable
        key={props.tableKey}
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={false}
        isPaginationEnabled={props.enablePagination}
        expandColAccessor={'expanded-row' as any}
        paginationOptions={{
          pageCount: Math.ceil(data?.count ?? 0 / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        onRowDoubleClick={(row) => {
          const note = row.original
          setActiveNote({ note, isViewing: true })
        }}
      />

      {activeNote && (
        <NotesModal
          chainId={props.chainId}
          contactCompanyId={props.contactCompanyId}
          show={!!activeNote}
          noteToEdit={activeNote.note}
          viewMode={activeNote.isViewing}
          onSubmitSuccess={() => {
            refetch()
          }}
          onEditMode={() => {
            setActiveNote((prev) =>
              prev ? { ...prev, isViewing: false } : null
            )
          }}
          handleClose={() => {
            setActiveNote(null)
          }}
        />
      )}
    </TableContainer>
  )
}

export function NotesTable(
  props: NotesTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={tableKey}>
        <NotesTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
  max-height: 600px;
  width: 100%;
`
