import React, { useMemo } from 'react'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Restaurant } from '../../../models/restaurant'

import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'

import { PredictedTrafficBadge } from 'components/PredictedTrafficBadge/PredictedTrafficBadge'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { menu_ingredients } from 'models/menu_ingredients'
import { BiFoodMenu } from 'react-icons/bi'
import { featureFlagService } from 'utils/featureFlagService'
import {
  formatInteger,
  formatPercentage,
  formatUsd,
} from '../../../utils/formatting'
import { getPathFromUrl } from '../../../utils/getPathFromUrl'
import { DataTableColumnSort } from '../../DataTable/DataTableSorting/DataTableSortingStore'

import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { DOORS_COLUMNS } from 'components/DataExpansionComponents/Doors/doorsColumnsInfo'
import { OverflownText } from '../../OverflownText'
import { Anchor } from '../../UI/Anchor'
import { LinkCell } from '../CommonTableCells/LinkCell'

export function useRestaurantsTableColumns(
  setMenuModal: React.Dispatch<
    React.SetStateAction<
      | {
          doorId: number
          doorName?: string
          itemsIds?: number[]
          menuLength?: number
        }
      | undefined
    >
  >,
  isChainTable: boolean,
  chainFilter: number | undefined,
  selectedProducts: number[],
  sorting: DataTableColumnSort[] | undefined
) {
  const featureFlag = featureFlagService()
  const { preferences } = usePreferences()
  const columnHelper = createColumnHelper<Restaurant>()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const doorsColumnHelper = createTableColumnHelper({
    columns: DOORS_COLUMNS,
    tooltips: tooltips,
  })

  const columns = useMemo(() => {
    let titleCols = [
      columnHelper.accessor('restaurant_name', {
        ...doorsColumnHelper.retrieveColumn('restaurant_name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        cell: (info) => {
          if (!info.getValue()) return '-'

          return (
            <FbLink
              to={`/door/${info.row.original.firstbite_id}`}
              target="_blank"
            >
              {info.getValue()}
            </FbLink>
          )
        },
        size: 250,
      }),
      columnHelper.accessor('chain_name', {
        ...doorsColumnHelper.retrieveColumn('chain_name'),
        size: 250,
        cell: (info) => {
          const name = info.row.original.chain_name
          const id = info.row.original.chain_proxy?.chain?.id
          if (!name) return '-'

          return (
            <FbLink
              to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${id}`}
              target="_blank"
            >
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor('chain_count', {
        ...doorsColumnHelper.retrieveColumn('chain_count', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) =>
          info.getValue() ? formatInteger(info.getValue()) : '1',
      }),
      columnHelper.accessor('cuisine_50', {
        ...doorsColumnHelper.retrieveColumn('cuisine_50'),
        size: 150,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('address_info.country', {
        ...doorsColumnHelper.retrieveColumn('country'),
        size: 100,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('full_address', {
        ...doorsColumnHelper.retrieveColumn('full_address'),
        size: 200,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('address_info.city', {
        ...doorsColumnHelper.retrieveColumn('city'),
        size: 200,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('address_info.state', {
        ...doorsColumnHelper.retrieveColumn('state'),
        size: 100,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('address_info.zip', {
        ...doorsColumnHelper.retrieveColumn('zip'),
        size: 100,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('ltv_data.total_ltv', {
        ...doorsColumnHelper.retrieveColumn('total_ltv', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 200,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('ltv_data.brand_ltv', {
        ...doorsColumnHelper.retrieveColumn('brand_ltv', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('ltv_data.revenue_ltv', {
        ...doorsColumnHelper.retrieveColumn('revenue_ltv', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('ltv_data.one_year_total_value', {
        ...doorsColumnHelper.retrieveColumn('one_year_total_value', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('ltv_data.taro', {
        ...doorsColumnHelper.retrieveColumn('taro', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('ltv_data.tabo', {
        ...doorsColumnHelper.retrieveColumn('tabo', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      }),
      columnHelper.accessor('ltv_data.pounds_per_year', {
        ...doorsColumnHelper.retrieveColumn('pounds_per_year', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) =>
          info.getValue() ? formatInteger(info.getValue()) : '-',
      }),
    ] satisfies ColumnDef<Restaurant, any>[]

    if (!featureFlag.shouldShowDoorMenu) {
      titleCols = titleCols.filter((col) => col.id !== 'menu')
    }

    let cols = [
      columnHelper.accessor('avg_rating', {
        ...doorsColumnHelper.retrieveColumn('avg_rating', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 150,
        cell: (info) => info.getValue()?.toFixed(2) || '-',
      }),
      columnHelper.accessor('reviews_count', {
        ...doorsColumnHelper.retrieveColumn('reviews_count', {
          meta: {
            rightAlign: true,
          },
        }),
        header: 'Total Reviews',
        size: 150,
        cell: (info) => formatInteger(info.getValue()) || '-',
      }),
      columnHelper.accessor('expense_category', {
        ...doorsColumnHelper.retrieveColumn('expense_category'),
        size: 100,
        cell: (info) => {
          const value = info.getValue()
          if (value === 'nan' || !value) {
            return '-'
          } else {
            return value
          }
        },
      }),
      columnHelper.accessor('velocity_group', {
        ...doorsColumnHelper.retrieveColumn('velocity_group'),
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      }),
      columnHelper.accessor('chain_proxy.chain.instagram.url', {
        ...doorsColumnHelper.retrieveColumn('instagram_url'),
        cell: (info) => {
          const value = info.row.original?.chain_proxy?.chain?.instagram?.url
          if (!value) return '-'

          let url = ''
          try {
            url = getPathFromUrl(value)
          } catch (e) {
            return '-'
          }

          return (
            <Anchor href={'//' + value} target="_blank">
              @{url}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('chain_proxy.chain.instagram.followers', {
        ...doorsColumnHelper.retrieveColumn('instagram_followers'),
        cell: (info) => {
          const value =
            info.row.original.chain_proxy?.chain.instagram?.followers
          if (!value) return '-'
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('chain_proxy.chain.instagram.following', {
        ...doorsColumnHelper.retrieveColumn('instagram_following', {
          meta: {
            rightAlign: true,
          },
        }),
        cell: (info) => {
          const value =
            info.row.original.chain_proxy?.chain.instagram?.following
          if (!value) return '-'
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('chain_proxy.chain.instagram.posts', {
        ...doorsColumnHelper.retrieveColumn('instagram_posts', {
          meta: {
            rightAlign: true,
          },
        }),
        cell: (info) => {
          const value = info.row.original.chain_proxy?.chain.instagram?.posts
          if (!value) return '-'
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('chain_proxy.chain.instagram.text_percentile', {
        ...doorsColumnHelper.retrieveColumn('instagram_text_percentile'),
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      }),
      columnHelper.display({
        ...doorsColumnHelper.retrieveColumn('reputation_data'),
        size: 200,
        cell: (info) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { michelin_id, eater_id, infatuation_id } = info?.row?.original
          const reputationPlatforms = [
            michelin_id && 'Michelin',
            eater_id && 'Eater',
            infatuation_id && 'Infatuation',
          ].filter(Boolean)

          if (reputationPlatforms.length === 0) return '-'
          return (
            <div
              style={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {reputationPlatforms.map((platform, index) => (
                <Badge key={index} className="!bg-green-500">
                  {platform}
                </Badge>
              ))}
            </div>
          )
        },
      }),
      columnHelper.accessor('michelin_stars', {
        ...doorsColumnHelper.retrieveColumn('michelin_stars'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (value === undefined || value === null) return '-'
          return value
        },
      }),
      columnHelper.display({
        ...doorsColumnHelper.retrieveColumn('delivery_platforms'),
        size: 200,
        cell: (info) => {
          const { has_doordash_id, has_ubereats_id, has_grubhub_id } =
            // eslint-disable-next-line no-unsafe-optional-chaining
            info.row?.original
          const platforms = [
            has_doordash_id && 'Doordash',
            has_ubereats_id && 'Uber Eats',
            has_grubhub_id && 'Grubhub',
          ].filter(Boolean)

          if (platforms.length === 0) return '-'
          return (
            <div
              style={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {platforms.map((platform, index) => (
                <Badge key={index} bg="info">
                  {platform}
                </Badge>
              ))}
            </div>
          )
        },
      }),
      columnHelper.accessor('domain', {
        ...doorsColumnHelper.retrieveColumn('domain'),
        size: 200,
        cell: (info) => {
          const val = info.getValue()
          return val ? (
            <Anchor href={'//' + val} target="_blank">
              {val}
            </Anchor>
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('website', {
        ...doorsColumnHelper.retrieveColumn('website'),
        size: 100,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      }),
      columnHelper.accessor('phone', {
        ...doorsColumnHelper.retrieveColumn('phone'),
        size: 180,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.display({
        ...doorsColumnHelper.retrieveColumn('menu'),
        size: 100,
        cell: (info) => {
          const items = info.row.original?.search_items?.map(
            (item) => item.item_id
          )

          const menuLength = info.row.original?.menu_size ?? 0

          if (!menuLength)
            return (
              <div className="ml-1">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Unavailable</Tooltip>}
                >
                  <div className="max-w-max">
                    <BiFoodMenu size={20} className="text-gray-400" />
                  </div>
                </OverlayTrigger>
              </div>
            )

          return (
            <div className="ml-1">
              <BiFoodMenu
                size={20}
                onClick={() =>
                  setMenuModal({
                    doorId: info.row.original.firstbite_id,
                    doorName: info.row.original.restaurant_name,
                    itemsIds: items,
                    menuLength,
                  })
                }
                className="cursor-pointer"
              />
            </div>
          )
        },
      }),
      columnHelper.accessor('menu_size', {
        ...doorsColumnHelper.retrieveColumn('menu_size'),
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
        size: 100,
      }),
      columnHelper.accessor('menu_matches', {
        ...doorsColumnHelper.retrieveColumn('menu_matches', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 110,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      }),
      columnHelper.accessor('address_info.dmanamecbsa', {
        ...doorsColumnHelper.retrieveColumn('dmanamecbsa'),
        size: 250,
        cell: (info) => {
          const value = info.getValue()
          if (value === 'NaN' || !value) {
            return '-'
          } else {
            return value
          }
        },
      }),
      columnHelper.accessor('menu_url', {
        ...doorsColumnHelper.retrieveColumn('menu_url'),
        size: 100,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      }),
      columnHelper.accessor('google_place_url', {
        ...doorsColumnHelper.retrieveColumn('google_place_url'),
        size: 150,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      }),
    ] satisfies ColumnDef<Restaurant, any>[]

    if (!featureFlag.enableInstagremData) {
      cols = cols.filter((col) => !col.id?.includes('instagram'))
    }

    if (!featureFlag.shouldShowReputationData) {
      cols = cols.filter(
        (col) => !['reputation_data', 'michelin_stars'].includes(col?.id ?? '')
      )
    }

    const extraCols = [
      columnHelper.accessor('ingredients_matrix', {
        ...doorsColumnHelper.retrieveColumn('ingredients_matrix'),
        size: 500,
        cell: (info) => {
          const ingredients = info.row.original.ingredients_matrix
          const display: string[] = []

          if (!ingredients) return null

          const ingredientsValuesWhitelist = menu_ingredients.map(
            (item) => item.value
          )

          Object.entries(ingredients)
            .filter(([key]) => ingredientsValuesWhitelist.includes(key))
            .map(([key, value]) => {
              if (Number(value) > 0) display.push(key)
            })

          return <OverflownText>{display.join(', ')}</OverflownText>
        },
      }),
      ...getCommonContactCompanyColumns(columnHelper, sorting),
      columnHelper.accessor(
        'address_info.income_medianhouseholdincome_hh_cnt',
        {
          ...doorsColumnHelper.retrieveColumn(
            'income_medianhouseholdincome_hh_cnt'
          ),
          cell: (info) => formatUsd(info.getValue()),
        }
      ),
      columnHelper.accessor('address_info.pop_sqmi', {
        ...doorsColumnHelper.retrieveColumn('pop_sqmi'),
        cell: (info) => formatInteger(info.getValue() ?? 0) + '/mi²',
      }),
      columnHelper.display({
        ...doorsColumnHelper.retrieveColumn('hh_gt100k'),
        cell: (info) => {
          const value =
            (info?.row?.original?.address_info?.income_100000to149999_hh_pct +
              info?.row?.original?.address_info?.income_150000to199999_hh_pct +
              info?.row?.original?.address_info?.income_200000ormore_hh_pct) *
            100
          return formatPercentage(value ?? 0) + '%'
        },
      }),
      columnHelper.accessor(
        'address_info.employment_unemployed_rate_county_level_only_pct',
        {
          ...doorsColumnHelper.retrieveColumn(
            'employment_unemployed_rate_county_level_only_pct'
          ),
          cell: (info) => formatInteger((info.getValue() ?? 0) * 100) + '%',
        }
      ),
    ]

    if (isChainTable) {
      titleCols = titleCols.filter(
        (el) =>
          el.id !== 'cuisine_50' &&
          el.id !== 'chain_name' &&
          el.id !== 'chain_count'
      )
      cols = cols.filter((el) => el.id !== 'expense_category')
    }

    if (!featureFlag.shouldShowMenuSize) {
      cols = cols.filter((col) => col.id !== 'menu_size')
    }

    if (chainFilter) {
      return [...titleCols, ...cols, ...extraCols]
    }

    return [...titleCols, ...cols]
  }, [
    selectedProducts,
    featureFlag.shouldShowMenuSize,
    featureFlag.enableInstagremData,
    featureFlag.shouldShowReputationData,
  ])

  return columns
}
