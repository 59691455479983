import styled from 'styled-components/macro'
import { HTMLAttributes } from 'react'
import { cn } from '../../../components/UI/cn'

export const SettingsTableHeader = ({
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        className,
        'flex flex-row justify-between items-start mb-3'
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export const TableDescriptionText = styled.p`
  font-size: 14px;
  color: #666;
  max-width: 70%;
`

export const SettingsTableHeaderContainer = styled.div`
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: start;
`

export const SettingsTableContainer = styled.div`
  padding: 0px 25px 80px 25px;
  box-sizing: border-box;
`
