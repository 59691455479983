import { Campaign } from './campaign'
import { ContactCompany, ContactCompanyCreateRequest } from './companies'
import { Tag } from './tags'

export interface ContactListResponse {
  results: ContactResponse[]
  count: number
}

export interface ContactResponse {
  id: number
  contact_company: ContactCompany
  first_name: string
  last_name: string
  title: string
  seniority: number
  department: number
  notes: string
  email_work: string | null
  email_personal: string | null
  email_generic: string | null
  email_work_validity: number | null
  email_personal_validity: number | null
  email_generic_validity: number | null
  url: string
  linkedin_url: string
  extension?: string
  phone_number: string
  starred: boolean

  note?: any
  tags: Tag[] // contact labels
  consent_to_contact: boolean
  company_size?: number
  company_cuisine_50?: string
  created: string
  modified: string
  source?: number
  requested_contact?: {
    id: number
    job: {
      campaign: Campaign
    }
  }
}

export interface ContactCreateRequest {
  contact_company: ContactCompanyCreateRequest
  category_id?: number
  sub_category_id?: number
  first_name: string
  last_name: string
  title?: string
  seniority?: Seniority | null
  department?: Department | null
  notes?: string
  tags?: number[]
  email_work?: string | null
  email_personal?: string | null
  email_generic?: string | null
  url?: string
  linkedin_url?: string
  phone_number?: string
  consent_to_contact: boolean
  extension?: string
}

export interface FailedContact {
  first_name: string
  last_name: string
  company: string
  company_domain: string
  email: string
  phone_number: string
  url: string
  notes: string
  title: string
  type: string
  errors: {
    [key: string]: string[]
  }
}

export interface ContactsImportResponse {
  total_count: number
  failed_count: number
  success_count: number
  success_contacts: ContactResponse[]
  failed_contacts: FailedContact[]
  failed_contacts_csv: string
  unmatched_companies?: string[]
}

// EmailValidity
export enum EmailValidity {
  INVALID = 0,
  VALID = 1,
  CATCH_ALL = 3,
  UNKNOWN = 4,
  SPAMTRAP = 5,
  ABUSE = 6,
  DO_NOT_MAIL = 7,
}

export const EmailValidityLabels: { [key in EmailValidity]: string } = {
  [EmailValidity.INVALID]: 'Invalid',
  [EmailValidity.VALID]: 'Valid',
  [EmailValidity.CATCH_ALL]: 'Catch All',
  [EmailValidity.UNKNOWN]: 'Unknown',
  [EmailValidity.SPAMTRAP]: 'Spamtrap',
  [EmailValidity.ABUSE]: 'Abuse',
  [EmailValidity.DO_NOT_MAIL]: 'Do Not Mail',
}

// ContactSource
export enum ContactSource {
  INTERNAL = 0,
  FIRSTBITE = 1,
}

export const ContactSourceLabels: { [key in ContactSource]: string } = {
  [ContactSource.INTERNAL]: 'Internal',
  [ContactSource.FIRSTBITE]: 'First Bite',
}

// classifications

export enum Seniority {
  CLEVEL = 1,
  FRANCHISEE = 2,
  VP = 3,
  DIRECTOR = 4,
  MANAGER = 5,
  IC = 6,
  INVESTOR = 7,
  HOURLY = 8,
}

export const SeniorityLabels: { [key in Seniority]: string } = {
  [Seniority.CLEVEL]: 'C-Level',
  [Seniority.FRANCHISEE]: 'Franchisee',
  [Seniority.VP]: 'VP',
  [Seniority.DIRECTOR]: 'Director',
  [Seniority.MANAGER]: 'Manager',
  [Seniority.IC]: 'Individual Contributor',
  [Seniority.HOURLY]: 'Hourly',
  [Seniority.INVESTOR]: 'Investor',
}

export enum Department {
  CULINARY = 1,
  SALES_AND_MARKETING = 2,
  IT = 3,
  PROCUREMENT = 4,
  SUPPLY_CHAIN_AND_OPERATIONS = 5,
  HR = 6,
  OTHER_LEADERSHIP = 7,
  OTHER = 8,
  RETAIL = 9,
  FINANCE = 10,
  LEGAL = 11,
}

export const DepartmentLabels: { [key in Department]: string } = {
  [Department.CULINARY]: 'Culinary',
  [Department.SALES_AND_MARKETING]: 'Sales and Marketing',
  [Department.IT]: 'IT',
  [Department.PROCUREMENT]: 'Procurement',
  [Department.SUPPLY_CHAIN_AND_OPERATIONS]: 'Supply Chain and Operations',
  [Department.HR]: 'HR',
  [Department.OTHER_LEADERSHIP]: 'Other Leadership',
  [Department.OTHER]: 'Other',
  [Department.RETAIL]: 'Retail',
  [Department.FINANCE]: 'Finance',
  [Department.LEGAL]: 'Legal',
}

// EmailCategory
export enum EmailCategory {
  GENERIC = 1,
  PERSONAL = 2,
  WORK = 3,
}

export const EmailCategoryLabels: { [key in EmailCategory]: string } = {
  [EmailCategory.GENERIC]: 'Other',
  [EmailCategory.PERSONAL]: 'Personal',
  [EmailCategory.WORK]: 'Work',
}
